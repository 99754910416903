@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-width: 100%;
  max-width: 100%;
}

html {
  display: flex;
  min-height: 100%;
  max-height: 100%;
}

body,
#root {
  flex-grow: 1;
  display: flex;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.notificationWrapper {
  @apply flex flex-row items-center justify-between w-52 bg-[#eaa786] px-4 py-6 text-black shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out;
}

.iconWrapper {
  @apply text-xl;
}

.contentWrapper {
  @apply flex flex-col items-start justify-center ml-4 cursor-default;
}

.contentWrapper h1 {
  @apply text-base text-black font-semibold leading-none tracking-wider;
}

.contentWrapper p {
  @apply text-sm text-black mt-2 leading-relaxed tracking-wider;
}

.closeIcon {
  @apply absolute top-2 right-2 cursor-pointer text-lg;
}

.react-daterange-picker__wrapper {
  border-radius: 5px;
}